.portfolio-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
  }
  
  .portfolio-works {
    display: grid;
  }
  
  .load-after {
    text-align: center;
    margin-top: 50px;
  }
  
  .load-more {
    margin-top: 50px;
    justify-self: center;
  }
  
  .portfolio-item {
    cursor: pointer;
    color: var(--title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
  }
  
  .portfolio-item:hover {
    background-color: var(--title-color);
    color: var(--container-color);
  }
  
  .portfolio-container {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
    justify-items: center;
  }
  
  .portfolio-card {
    background-color: var(--container-color);
    width: 460px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    border-radius: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .portfolio-card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .portfolio-img {
    width: 460px;
    height: auto;
    border-radius: 1rem;
    margin-bottom: var(--mb-1);
  }
  
  .portfolio-title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
  }
  
  .portfolio-desc {
    font-size: var(--smaller-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-0-5);
  }
  
  .portfolio-btn {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }

  .portfolio-btn {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }
  
  .portfolio-btn-icon {
    font-size: 1rem;
    transition: 0.4s;
  }
  
  .portfolio-btn:hover .portfolio-btn-icon {
    transform: translateX(0.25rem);
  }
  
  .active-portfolio {
    background-color: var(--title-color);
    color: var(--container-color);
  }
  
  @media screen and (max-width: 992px) {
    .portfolio-container {
      grid-template-columns: none;
      gap: 1.25rem;
    }
  
    .portfolio-card {
      padding: 1rem;
    }
  
    .portfolio-img {
      margin-bottom: 0.75rem;
    }
  
    .portfolio-title,
    .portfolio-desc {
      margin-bottom: 0.25;
    }
  }
  
  @media screen and (max-width: 500px) {
    .portfolio-img,
    .portfolio-card {
      width: 300px;
    }
  
    .portfolio-container {
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 350px) {
    .portfolio-item {
      font-size: var(--small-font-size);
    }
  
    .portfolio-filters {
      column-gap: 0.25rem;
    }
  
    .portfolio-img,
    .portfolio-card {
      width: 300px;
    }
  }
  