.about__container{
    grid-template-columns: repeat(2, 1fr);
    align-items: center; 
    column-gap: 4rem;
}

.about__img{
    width: 350px;
    border-radius: 2.0rem;
    justify-self: center ;
}

.about__info{
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
     margin-bottom: var(--mb-2);
}

.about__box{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon{
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}

.about__title{
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle{
    font-size: var(--tiny-font-size);
}

@media screen and (max-width: 992px){
    .about__container{
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img{
        width: 220px;
    }

    .about__box{
        padding: 0.75rem 0.5rem;
    }

    .about__data{
        text-align: center;
    }

    .about__info{
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .about__container{
        grid-template-columns: 0.7fr;
        row-gap: 1.5rem;
    }

    .about__img{
        grid-column: 1/3;
        width: 150px;
    }

    .about__box{
        grid-column: 2/3;
        padding: 0.25rem 0.25rem;
        margin: 0.25rem 1rem;
  
    }

    .about__data{
        grid-column: 1/3;
        text-align: center;
    }

    .about__info{
        justify-content: center;
    }
}


@media screen and (max-width: 500px) {
    .about__container{
        grid-template-columns: 0.4fr;
        row-gap: 1rem;
    }

    .about__img{
        width: 100px;
    }

    .about__box{
        grid-column: 2/3;
        padding: 0.1rem 0.2rem;
        margin: 0.2rem 0.7rem;
  
    }

    .about__data{
        grid-column: 1/3;
        text-align: center;
    }

    .about__info{
        justify-content: center;
    }
}