.section {
    padding: 6rem 0 2rem;
  }
  
  .section-title {
    font-size: var(--h1-font-size);
    color: var(--title-color);
  }
  
  .section-subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
  }
  
  .section-title,
  .section-subtitle {
    text-align: center;
  }

.contact-container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
  }
  
  .contact-title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
  }
  
  .contact-info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
  }
  
  .contact-card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
  }
  
  .contact-card-icon {
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
  }
  
  .contact-card-title,
  .contact-card-data {
    font-size: var(--small-font-size);
  }
  
  .contact-card-title {
    font-weight: var(--font-medium);
  }
  
  .contact-card-data {
    display: block;
    margin-bottom: var(--mb-0-75);
  }
  
  .contact-button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
  }
  
  .contact-button-icon {
    font-size: 1rem;
    transition: 0.3s;
  }
  
  .contact-button:hover .contact-button-icon {
    transform: translate(0.25rem);
  }
  
  .contact-form {
    width: 360px;
  }
  
  .contact-form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
  }
  
  .contact-form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid Orgba(0, 0, 0, 0.3);
    background: none;
    color: var (--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
  }
  
  .contact-form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
  }
  
  .contact-form-area {
    height: 11rem;
  }
  
  .contact-form-area textarea {
    resize: none;
  }
  
  /* BREAKPOINTS */
  /* For large devices */
  
  @media screen and (max-width: 992px) {
    .contact-container {
      column-gap: 3rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .contact-container {
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
  
    .contact-info {
      justify-content: center;
    }
  
    .contact-form {
      margin: 0 auto;
    }
  }
  
  @media screen and (max-width: 576px) {
    .contact-info {
      grid-template-columns: 1fr;
    }
  
    .contact-form {
      width: 100%;
    }
  }
  